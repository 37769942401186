import React from 'react';
import Navbar from './Navbar';
import './Home.css';
import PC from './images/Computer&Joystick.jpg';
import Pharma from './images/Pharmacy.jpg';
import Doc from './images/Doctor.jpg';

  
function Home() {
  return (
  <>
  <Navbar/>
    <div className="home-container">
      <header className="bg-primary text-white text-center py-5">
        <h1>Bienvenue sur Benhamideche</h1>
        <p className="lead">Nous sommes un groupe et une famille de professionnels passionnés, chacun avec ses propres talents et parcours. Ce site a pour but de partager nos projets, nos expériences, et nos services avec le monde.</p>
      </header>

      

      <section className="services bg-light py-5">
        <div className="container">
          <h2>Nos services</h2>
          <div className="row">
            <div className="col-md-4">
              <img className='ImagePortfolio' src={PC}  alt='Compute&Joystick'/>
              <p></p>
            </div>
            <div className="col-md-4">
            <img className='ImagePortfolio' src={Pharma} alt='Compute&Joystick'/>
              <p></p>
            </div>
            <div className="col-md-4">
            <img className='ImagePortfolio' src={Doc} alt='Compute&Joystick'/>
              
              <p></p>
            </div>
          </div>
        </div>
      </section>

      <footer className="text-center bg-dark text-white py-4">
        <p>&copy; 2024 Benhamideche. Tous droits réservés.</p>
      </footer>
    </div>
   </>
  );
  }

export default Home;
